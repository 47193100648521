import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  ClaimsDateProvider,
  TitleTooltip,
  ServiceLineSelect,
  PatientTypeSelect,
  MarketOrTagSelect,
  Paginateable,
  SharedAffiliationChart,
  LoadingView,
} from 'components';
import { convertToCSV } from 'documents/downloadTypes';
import {
  CsvUtil,
  DateUtil,
  StoreUtil,
  PatientType,
} from 'doctivity-shared/utils';
import { MarketsUtil, ServiceLineUtil } from 'utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { listMarkets } from 'store/actions/marketsActions';
import fileDownload from 'js-file-download';
import { TagsUtil } from 'utils';
import { withReportCriteria } from 'market-data/useReportCriteria';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  headerDropDown: {
    minWidth: 220,
    marginBottom: theme.spacing(2),
  },
  sortRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {},
  filterDropDown: {
    width: 120,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dropDown: {
    minWidth: 120,
    marginBottom: theme.spacing(2),
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filterLabel: {
    backgroundColor: 'white',
    paddingRight: 4,
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  spacer: {
    width: theme.spacing(),
  },
  expandSpacer: {
    flexGrow: 1,
  },
  networkTooltip: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    fontWeight: 400,
  },
  networkTooltipProviderLabel: {
    fontWeight: 500,
  },
});

class ClaimsSharedAffiliation extends React.Component {
  // Number of seconds to wait before saving the currently selected criteria
  saveCriteriaInterval = 3;

  saveCriteriaTimer = null;

  constructor(props) {
    super(props);

    this.state = {
      defaultParams: false, // Used when user does not have any saved criteria and going to /claims with no query string
      sort: ['shared_patients', 'DESC'],
      query: {
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    if (
      !this.props.markets ||
      StoreUtil.needsLoadLongCache(this.props.markets)
    ) {
      dispatch(listMarkets(this.props.clientId));
    }

    if (StoreUtil.needsLoadNoCache(this.getData())) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveCriteriaTimer);
  }

  getData() {
    if (this.state.groupByServicelines) {
      return this.props.networkByServiceline;
    }
    return this.props.network;
  }

  marketsChanged(newMarkets, oldMarkets) {
    if (
      newMarkets === null ||
      (oldMarkets === null && newMarkets !== oldMarkets)
    ) {
      return true;
    }
    if (newMarkets && oldMarkets && newMarkets.data && oldMarkets.data) {
      if (newMarkets.data.length !== oldMarkets.data.length) {
        return true;
      }
      if (
        newMarkets.meta &&
        newMarkets.meta.statusTime &&
        oldMarkets.meta &&
        oldMarkets.meta.statusTime
      ) {
        return (
          newMarkets.meta.statusTime.statusSuccess !==
          oldMarkets.meta.statusTime.statusSuccess
        );
      }
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;

    if (
      this.props.clientId !== prevProps.clientId ||
      this.marketsChanged(this.props.markets, prevProps.markets)
    ) {
      router.navigate('/claims?report=shared_affiliation');
      return;
    }
    if (
      this.state.defaultParams &&
      (querySize === 0 ||
        (querySize === 1 && router.query.has('report')))
    ) {
      const marketsLoaded = StoreUtil.isLoaded(this.props.markets);
      if (marketsLoaded) {
        // console.log('set default params');
        this.setState({
          defaultParams: false,
        });
        this.props.updateCriteria({
          before: false,
          serviceline: ServiceLineUtil.getDefault(),
          isMarket: true,
          market: this.props.market,
          patientType: PatientType.ALL_PATIENTS,
          affiliation: 'all',
          groupByServicelines: false,
        });
      }
    }
    if (
      this.props.currentCriteria !== prevProps.currentCriteria &&
      this.props.currentCriteria.ready
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, clientId, currentCriteria: current } = this.props;
    const { serviceline, before, affiliation, ready } = current;
    if ((!current.market && !current.tag) || !ready) {
      return;
    }
    const market = current.isMarket
      ? this.props.markets?.data?.find((c) => c.id === current.market)
      : null;
    const tag = current.isMarket
      ? null
      : TagsUtil.findTag(current.tag, this.props.tagNamespaces);
    let searchServiceline = (Array.isArray(serviceline) ? serviceline[0] : serviceline);
    if (searchServiceline === 'all' || searchServiceline === undefined) {
      searchServiceline = undefined;
    } else {
      searchServiceline = searchServiceline ?? ServiceLineUtil.getDefault()
    }

    dispatch(
      loadAnalytics({
        type: !current.groupByServicelines
          ? 'CLAIMS_SHARED_AFFILIATION'
          : 'CLAIMS_SHARED_AFFILIATION_BY_SERVICELINE',
        filter: {
          serviceline: searchServiceline,
          before,
          client_id: clientId,
          group_by_serviceline: current.groupByServicelines,
          market: current.isMarket ? market : undefined,
          tag: !current.isMarket ? tag : undefined,
          patient_type: current.patientType || PatientType.ALL_PATIENTS,
          affiliated:
            affiliation === 'all'
              ? undefined
              : affiliation === 'affiliated'
                ? true
                : false,
        },
        opts: {
          ...this.state.query,
          sort: this.state.sort,
        },
      }),
    );
  }

  render() {
    const { classes, currentCriteria: current } = this.props;
    const {
      groupByServicelines,
      serviceline,
      before,
      isMarket,
      tag: tagId,
      market: marketId,
      affiliation,
      patientType,
      ready,
    } = current;
    let market, tag;
    if (!ready) {
      return <LoadingView />;
    }
    if (isMarket) {
      market = this.props.markets?.data?.find((m) => m.id === marketId);
    } else {
      tag = TagsUtil.findTag(tagId, this.props.tagNamespaces);
    }

    const network = this.getData();

    const hasChildren =
      ServiceLineUtil.findServiceLine(this.props.servicelines, serviceline)
        ?.children?.length > 0;
    let searchServiceline = (Array.isArray(serviceline) ? serviceline[0] : serviceline);
    if (searchServiceline === 'all' || searchServiceline === undefined) {
      searchServiceline = undefined;
    } else {
      searchServiceline = searchServiceline ?? ServiceLineUtil.getDefault()
    }

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <FormControl
            variant='outlined'
            className={classes.headerDropDown}
            key='group-by'
          >
            <InputLabel className={classes.filterLabel}>Group By</InputLabel>
            <Select
              key='select'
              value={groupByServicelines}
              onChange={this.onGroupByChange}
              label='Group By'
            >
              <MenuItem value={false} key='providers'>
                Providers
              </MenuItem>
              <MenuItem value={true} key='servicelines'>
                Service Lines
              </MenuItem>
            </Select>
          </FormControl>
          <ClaimsDateProvider />
        </div>
        <Card className={classes.card}>
          <CardContent>
            <TitleTooltip
              title='Shared Patients by Provider Affiliation'
              tooltip='This chart shows the % of patients that are shared with other Affiliated Providers by the selected filters. The chart is sorted by a directional calculation of total projected patients or total projected pediatric patients.'
              onExport={this.onExport}
            />
            <div className={classes.dropDownContainer}>
              <FormControl
                variant='outlined'
                className={classes.filterDropDown}
                key='direction'
              >
                <InputLabel className={classes.filterLabel}>
                  Claims Date
                </InputLabel>
                <Select
                  key='select'
                  value={before}
                  onChange={this.onDirectionChange}
                  label='Patient Seen By'
                >
                  <MenuItem value={false} key='after'>
                    After
                  </MenuItem>
                  <MenuItem value key='before'>
                    Before
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.dropDown}>
                <MarketOrTagSelect
                  filter={{
                    type: isMarket ? 'market' : 'tag',
                    market,
                    tag,
                  }}
                  onChange={this.onMarketChange}
                  allowTagSelection={true}
                  markets={this.props.markets}
                  market={market}
                  type='providers'
                />
              </div>
              <div className={classes.dropDown}>
                <ServiceLineSelect
                  value={searchServiceline ?? 'all'}
                  onChange={this.onServicelineChange}
                  showSubServicelines={!groupByServicelines}
                  hideChildlessTopLevels={groupByServicelines}
                  showAll
                />
              </div>
              <div className={classes.dropDown}>
                <PatientTypeSelect
                  noCapturedClaims
                  noModifyAppState
                  onChange={this.onPatientTypeChange}
                  value={patientType.toUpperCase()}
                />
              </div>
              <FormControl
                variant='outlined'
                className={classes.dropDown}
                key='affiliated'
              >
                <InputLabel className={classes.filterLabel}>
                  Affiliation
                </InputLabel>
                <Select
                  key='select'
                  value={affiliation}
                  onChange={this.onAffiliationChange}
                  label='Affiliation'
                >
                  <MenuItem value='all' key='all'>
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value='affiliated' key='affiliated'>
                    Affiliated
                  </MenuItem>
                  <MenuItem value='non-affiliated' key='non-affiliated'>
                    Non-Affiliated
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.sortRow}>
              <FormControl
                variant='outlined'
                className={classes.dropDown}
                key='sort'
              >
                <InputLabel className={classes.filterLabel}>Sort By</InputLabel>
                <Select
                  key='select'
                  value={this.state.sort[0]}
                  onChange={this.onSortChange}
                  label='Sort By'
                >
                  <MenuItem value='shared_patients' key='shared_patients'>
                    Total Shared Patients
                  </MenuItem>
                  <MenuItem
                    value='shared_patients_affiliated'
                    key='shared_patients_affiliated'
                  >
                    Patients from Affiliated Providers
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.spacer} />
              <FormControl
                variant='outlined'
                className={classes.dropDown}
                key='sort-direction'
              >
                <InputLabel className={classes.filterLabel}>
                  Sort Direction
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.sort[1]}
                  onChange={this.onSortDirectionChange}
                  label='Sort Direction'
                >
                  <MenuItem value='DESC' key='DESC'>
                    Highest to Lowest
                  </MenuItem>
                  <MenuItem value='ASC' key='ASC'>
                    Lowest to Highest
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.expandSpacer} />
              {!groupByServicelines && network && (
                <Paginateable
                  data={network.data}
                  query={this.state.query}
                  onQueryChange={this.onQueryChange}
                />
              )}
            </div>
            {ready && (
              <SharedAffiliationChart
                classes={classes}
                groupByServicelines={groupByServicelines}
                hasChildren={hasChildren}
                serviceline={searchServiceline}
                before={before}
                isMarket={isMarket}
                onServicelineClick={this.onServicelineClick}
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  }

  onServicelineClick = (sl) => {
    this.props.updateCriteria({
      serviceline: sl.id,
    });
  };

  onServicelineChange = (id) => {
    this.props.updateCriteria({
      serviceline: id,
    });
  };

  onDirectionChange = (event) => {
    this.props.updateCriteria({
      before: event.target.value,
    });
  };

  onAffiliationChange = (event) => {
    this.props.updateCriteria({ affiliation: event.target.value });
  };

  onPatientTypeChange = (value) => {
    this.props.updateCriteria({ patientType: value });
  };

  onMarketChange = (filter) => {
    const { market, tag } = this.props.currentCriteria;
    this.props.updateCriteria({
      isMarket: filter.type === 'market',
      market: (filter.type === 'market' ? filter.market : market)?.id,
      tag: (filter.type === 'tag' ? filter.tag : tag)?.id,
    });
  };

  onCriteriaChanged = () => {
    this.fetchData();
  };

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  onSortChange = (event) => {
    this.setState(
      (state) => ({ sort: [event.target.value, state.sort[1]] }),
      this.fetchData,
    );
  };

  onSortDirectionChange = (event) => {
    this.setState(
      (state) => ({ sort: [state.sort[0], event.target.value] }),
      this.fetchData,
    );
  };

  onGroupByChange = (event) => {
    if (event.target.value) {
      const sl = ServiceLineUtil.findServiceLine(
        this.props.servicelines,
        this.props.currentCriteria.serviceline,
      );
      if (sl && sl.parent_id) {
        this.props.updateCriteria({
          serviceline: sl.parent_id,
        });
      }
    }
    this.props.updateCriteria({ groupByServicelines: event.target.value });
  };

  onExport = async () => {
    const {
      servicelines,
      currentCriteria: {
        isMarket,
        tag,
        market,
        serviceline: selectedServiceline,
        groupByServicelines,
        patientType,
      },
    } = this.props;
    const slId = parseInt(selectedServiceline, 10);
    const selectedSL = servicelines?.find((l) => l.id === slId);
    const serviceline =
      selectedServiceline === 'all'
        ? 'ALL'
        : selectedSL?.abbreviation ?? selectedSL?.name;
    const beforeOrAfter = before ? 'Before' : 'After';
    const tagNS =
      !isMarket &&
      TagsUtil.findNamespaceForTagId(tag.id, this.props.tagNamespaces);
    const tagName =
      tag && TagsUtil.findTag(tag.id, this.props.tagNamespaces)?.name;
    const label = isMarket ? market.name : `${tagNS?.name}: ${tagName}`;

    if (groupByServicelines) {
      // Claims Date (before after), Market / Label, Service Line, Patients (Projected or Pediatrics), Percent Affiliated, Percent Non Affiliated
      const mapping = {
        displayName: 'Shared Patients By Provider Affiliation',
        helpText:
          'Download a .csv of the Shared Patients By Provider Affiliation report.',
        downloadName: `doctivity-SHARED-AFFILIATION-${beforeOrAfter.toLocaleUpperCase()}-${label.toLocaleUpperCase()}-${serviceline.toLocaleUpperCase()}-{date}.csv`,
        initialQuery: {},
        columnMapping: {
          claims_date: {
            downloadName: 'Claims Date',
            format: () => beforeOrAfter,
          },
          label: {
            downloadName: 'Market / Label',
            format: () => label,
          },
          name: {
            downloadName: 'Service Line',
          },
          patient_type: {
            downloadName: 'Patients',
            format: () =>
              patientType === 'PEDIATRIC_PATIENTS' ? 'Pediatric' : 'Projected',
          },
          shared_patients: {
            downloadName: 'Percent Affiliated',
            format: (_v, row) => {
              const affiliated = Number.isNaN(row.shared_patients_affiliated)
                ? 0
                : row.shared_patients_affiliated;
              const total = Number.isNaN(row.shared_patients)
                ? 0
                : row.shared_patients;
              const percent = total < 1 ? 100 : (affiliated / total) * 100;
              return percent.toFixed(2);
            },
          },
          shared_patients_non_affiliated: {
            downloadName: 'Percent Non-Affiliated',
            format: (_v, row) => {
              const affiliated = Number.isNaN(row.shared_patients_affiliated)
                ? 0
                : row.shared_patients_affiliated;
              const total = Number.isNaN(row.shared_patients)
                ? 0
                : row.shared_patients;
              const percent =
                total < 1 ? 0 : ((total - affiliated) / total) * 100;
              return percent.toFixed(2);
            },
          },
        },
      };
      const csv = convertToCSV(
        mapping,
        this.props.networkByServiceline.data.rows,
      );
      const downloadName = mapping.downloadName.replace(
        '{date}',
        moment().format('YY_MM_DD'),
      );
      fileDownload(csv, downloadName);
    } else {
      //Provider NPI, Before/After, Market or Label, Patients, Affiliation, Service Line, % Affiliated, % Non Affiliated
      const columns = [
        'Provider NPI',
        'First Name',
        'Last Name',
        'Before/After',
        'Market/Label',
        'Affiliation Status',
        'Service Line',
        '% Affiliated',
        '% Non Affiliated',
      ];

      const data = [columns];
      const {
        network,
        servicelines,
        servicelineList,
        currentCriteria: {
          isMarket,
          tag,
          market,
          patientType,
          serviceline: selectedServiceline,
          before,
        },
      } = this.props;
      const slId = parseInt(selectedServiceline, 10);
      const selectedSL = (servicelineList?.data ?? servicelines)?.find(
        (l) => l.id === slId,
      );
      const serviceline =
        selectedServiceline === 'all'
          ? 'ALL'
          : selectedSL?.abbreviation ?? selectedSL?.name;
      const beforeOrAfter = before ? 'Before' : 'After';
      const tagNS =
        !isMarket &&
        TagsUtil.findNamespaceForTagId(tag.id, this.props.tagNamespaces);
      const tagName =
        tag && TagsUtil.findTag(tag.id, this.props.tagNamespaces)?.name;
      const label = isMarket ? market.name : `${tagNS?.name}: ${tagName}`;
      network.data.rows.forEach((row) => {
        const percent =
          (row.shared_patients_affiliated / row.shared_patients) * 100;
        data.push([
          row.npi,
          row.first_name,
          row.last_name,
          beforeOrAfter,
          label,
          row.is_affiliated ? 'Affiliated' : 'Non-affiliated',
          serviceline,
          Number.isNaN(percent) ? 0 : percent.toFixed(2),
          Number.isNaN(percent) ? 100 : (100 - percent).toFixed(2),
        ]);
      });
      const pediatric =
        patientType === 'PEDIATRIC_PATIENTS' ? '_PEDIATRIC' : '';
      const date = DateUtil.formatDateForUser(new Date());
      const filename = `doctivity_SHARED_${beforeOrAfter.toLocaleUpperCase()}_${(serviceline ?? 'Unknown').toLocaleUpperCase()}${pediatric}_${date}.csv`;
      fileDownload(CsvUtil.matrixToString(data), filename);
    }
  };
}

ClaimsSharedAffiliation.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  query: PropTypes.object.isRequired,
  network: PropTypes.object,
  networkByServiceline: PropTypes.object,
  market: PropTypes.object,
  markets: PropTypes.object,
  user: PropTypes.object,
  tagNamespaces: PropTypes.object,
  servicelines: PropTypes.array,
  servicelineList: PropTypes.array,
  updateCriteria: PropTypes.func.isRequired,
  currentCriteria: PropTypes.object.isRequired,
  getCriteria: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    network: StoreUtil.get(state.analytics, 'CLAIMS_SHARED_AFFILIATION'),
    networkByServiceline: StoreUtil.get(
      state.analytics,
      'CLAIMS_SHARED_AFFILIATION_BY_SERVICELINE',
    ),
    market: MarketsUtil.getSelectedMarket(state),
    markets: MarketsUtil.getMarkets(state),
    servicelines: state.claims && state.claims.servicelines_grouped,
    servicelineList: state.claims && state.claims.servicelines_list,
    user: state.user,
    tagNamespaces: StoreUtil.get(
      state.tagNamespaces,
      'API_TAG_NAMESPACES_LIST_BY_CLIENT',
    ),
  };
}

const styled = withStyles(styles)(
  withReportCriteria('shared_affiliation', ClaimsSharedAffiliation),
);
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsSharedAffiliation };
