import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Map, Source, Layer } from 'react-map-gl';
import axios from 'axios';

import { LngLatBounds } from 'mapbox-gl';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Grid,
  Icon,
  IconButton,
  Tabs,
  Tab,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';

import { StateUtil, StoreUtil } from 'doctivity-shared/utils';

import {
  LoadingView,
  SmallIconTextButton,
  ConfirmationDialog,
  ZipCityList,
} from 'components';
import { listCountiesByState } from 'store/actions/countiesActions';
import { showConfirmationDialog } from 'store/actions/systemActions';
import LockIcon from '@mui/icons-material/Lock';

const mapboxToken =
  'pk.eyJ1IjoiZG9jdGl2aXR5IiwiYSI6ImNsMmo2OWx6bTBtdmUza3J6eDQ2Z3pvdG0ifQ.o9j6xJxOa2WKQLYdqQaJRQ';

const styles = (theme) => ({
  nameInput: {
    width: 450,
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  stateSelect: {
    width: 180,
    marginRight: theme.spacing(2),
  },
  countySelect: {
    width: 260,
    marginRight: theme.spacing(2),
  },
  stateDiv: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addStateButton: {},
  postalInput: {
    width: 450,
  },
  postalLabel: {
    color: '#cccccc',
  },
  lockIcon: {
    fontSize: 'inherit',
    color: '#999999',
  },
});

class EditMarketDialog extends Component {
  constructor(props) {
    super(props);
    this.mapRef = null;
    this.state = {
      name: null,
      editedName: false,
      geodata: null,
      byPostal: false,
      states: [],
      postals: '',
      postalList: [],
      isPrivate: false,
      dataModified: false,
      confirmCloseOpen: false,
    };
  }

  componentDidMount() {
    this.initStateFromProps();
    this.fetchCountyGeo();
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (
      JSON.stringify(this.props.market) !== JSON.stringify(prevProps.market)
    ) {
      this.initStateFromProps();
    }
  }

  initStateFromProps() {
    let states = [
      {
        abbr: 'PA',
      },
    ];
    let postals = '';
    let postalList = [];
    let name = '';
    let byPostal = false;
    let isPrivate = false;
    if (this.props.market) {
      name = this.props.market.name;
      byPostal = this.props.market.by_postal;
      isPrivate = this.props.market.is_private;
      if (byPostal && this.props.market.postals) {
        postals = this.props.market.postals.join(', ');
        postalList = this.props.market.postals;
      } else if (this.props.market.states) {
        states = this.props.market.states.slice(); // copy array
      }
    }
    // make sure they all have a county array
    states.forEach((state) => {
      if (!state.counties) {
        state.counties = [];
      }
      state.countiesOpen = false;
    });
    this.setState(
      (state) => ({
        ...state,
        name,
        states,
        byPostal,
        postals,
        postalList,
        isPrivate,
      }),
      () => {
        this.fetchCountiesForAllStates();
        if (this.state.geodata) {
          // if geo data is loaded
          this.setInitialMapState();
        }
      },
    );
  }

  fetchCountiesForAllStates() {
    if (this.state.states && this.state.states.length > 0) {
      this.state.states.forEach((state) => {
        if (
          !StoreUtil.isLoaded(
            StoreUtil.get(
              this.props.allCounties,
              'counties_by_state',
              state.abbr,
            ),
          )
        ) {
          this.fetchCounties(state.abbr);
        }
      });
    }
  }

  setInitialMapState(clearMap = true) {
    if (this.mapRef) {
      if (clearMap) {
        this.clearMap();
      }
      if (this.state.states && this.state.states.length > 0) {
        this.state.states.forEach((state) => {
          if (state.counties && state.counties.length === 0) {
            this.selectAllOnMap(state.abbr, true);
          } else if (state.counties) {
            state.counties.forEach((county) => {
              this.selectOnMap(county.geo_id, true);
            });
          }
        });
        this.zoomToBounds();
      }
    }
  }

  fetchCounties(state) {
    this.props.dispatch(listCountiesByState(state));
  }

  fetchCountyGeo() {
    axios.get('maps/counties.geojson').then((response) => {
      this.setState(
        (state) => ({
          ...state,
          geodata: response.data,
        }),
        this.setInitialMapState,
      );
    });
  }

  render() {
    const { classes, open } = this.props;

    const { isPrivate, confirmCloseOpen } = this.state;

    const showCheckbox =
      !this.props.market ||
      this.props.market?.user_id === null ||
      this.props.user.id === this.props.market?.user_id;

    let title = 'Edit Market';
    if (!this.props.market || !this.props.market.id) {
      title = 'Add Market';
    }

    return (
      <>
        <Dialog
          onClose={this.onCancel}
          aria-labelledby='simple-dialog-title'
          open={open}
          fullWidth
          maxWidth='lg'
          scroll='paper'
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers>
            {!this.state.geodata && <LoadingView />}
            {this.state.geodata && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div>
                    <TextField
                      className={classes.nameInput}
                      label='Name'
                      value={
                        this.state.name ||
                        (this.state.editedName ? '' : this.buildName())
                      }
                      onChange={this.onNameChange}
                    />
                  </div>
                  <Tabs
                    variant='scrollable'
                    className={classes.tabs}
                    value={this.state.byPostal ? 'postal' : 'counties'}
                    onChange={this.onTabChange}
                  >
                    <Tab
                      label='State & Counties'
                      value='counties'
                      disableRipple
                    />
                    <Tab label='Zip Codes' value='postal' disableRipple />
                  </Tabs>

                  {this.state.byPostal && (
                    <>
                      <TextField
                        className={classes.postalInput}
                        label='Zip Codes'
                        value={this.state.postals}
                        multiline
                        rows={10}
                        onChange={this.onPostalChange}
                      />
                      <Typography className={classes.postalLabel}>
                        Enter 5 digit zip codes seperated by commas.
                      </Typography>
                    </>
                  )}
                  {!this.state.byPostal && (
                    <>
                      {this.buildCountySelects()}
                      <SmallIconTextButton
                        icon='add'
                        text='Add State'
                        className={classes.addStateButton}
                        onClick={this.onAddState}
                      />
                    </>
                  )}

                  {showCheckbox ? (
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <LockIcon className={classes.lockIcon} />
                      <Typography>Make Private</Typography>
                      <Checkbox
                        checked={isPrivate}
                        onChange={this.togglePrivate}
                      />
                    </Stack>
                  ) : (
                    <Typography>
                      This is a public market created by another user.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {this.state.byPostal ? (
                    <ZipCityList
                      zipList={this.state.postalList}
                    />
                  ) : (
                    <Map
                      ref={this.onSetMapRef}
                      initialViewState={{
                        longitude: -100,
                        latitude: 40,
                        zoom: 6,
                      }}
                      style={{
                        minHeight: 400,
                        opacity: this.state.byPostal ? 0.1 : 1,
                      }}
                      mapStyle='mapbox://styles/mapbox/light-v10'
                      mapboxAccessToken={mapboxToken}
                      interactiveLayerIds={['county-lines']}
                      onClick={(event) => {
                        const features = this.mapRef.queryRenderedFeatures(
                          event.point,
                          { layers: ['county-lines'] },
                        );
                        if (features.length > 0) {
                          this.setCountySelected(
                            StateUtil.getAbbrForFips(
                              features[0].properties.STATEFP,
                            ),
                            features[0].id,
                            !features[0].state.selected,
                          );
                        }
                      }}
                      onLoad={this.onMapLoaded}
                      attributionControl={false}
                    >
                      <Source
                        id='counties'
                        type='geojson'
                        data={this.state.geodata}
                        promoteId='GEOID'
                      >
                        <Layer
                          id='county-lines'
                          type='fill'
                          paint={{
                            'fill-color': '#00A887',
                            'fill-opacity': [
                              'case',
                              ['boolean', ['feature-state', 'selected'], false],
                              0.6,
                              0.075,
                            ],
                          }}
                        />
                      </Source>
                    </Map>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={this.onSave}
              color='primary'
              disabled={
                this.state.editedName ? this.state.name.length < 1 : false
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          open={confirmCloseOpen}
          title='Close Dialog?'
          content='You will lose any unsaved changes.'
          onClose={this.onCloseConfirmCancel}
          onCancel={this.onCloseConfirmCancel}
          onConfirm={this.onConfirmCancel}
        />
      </>
    );
  }

  buildCountySelects() {
    if (this.state.states && this.state.states.length > 0) {
      return this.state.states.map((state, i) => {
        let counties = StoreUtil.get(
          this.props.allCounties,
          'counties_by_state',
          state.abbr,
        );

        if (!counties || !StoreUtil.isLoaded(counties)) {
          return <LoadingView key={`${state.abbr}-loading`} />;
        }
        counties = StoreUtil.getData(counties);

        return (
          <div
            key={`${state.abbr}-state-div`}
            className={this.props.classes.stateDiv}
          >
            <FormControl
              variant='outlined'
              className={this.props.classes.stateSelect}
              key={`${state.abbr}-state-control`}
            >
              <InputLabel>State</InputLabel>
              <Select
                value={state.abbr}
                onChange={(event) => {
                  this.onStateChange(state.abbr, event.target.value);
                }}
                label='State'
              >
                <MenuItem value='all' key='all'>
                  <em>All</em>
                </MenuItem>
                {StateUtil.getStates().map((s) => (
                  <MenuItem value={s.abbr} key={s.abbr}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={this.props.classes.countySelect}
              key={`${state.abbr}-counties-control`}
            >
              <InputLabel>County</InputLabel>
              <Select
                key={`${state.abbr}-counties-select`}
                value={state.counties.length === 0 ? ['all'] : state.counties}
                onChange={(event) => {
                  this.onCountyChange(state.abbr, event.target.value);
                }}
                open={state.countiesOpen}
                onOpen={() => {
                  this.onOpenCounties(state.abbr, true);
                }}
                onClose={() => {
                  this.onOpenCounties(state.abbr, false);
                }}
                label='County'
                renderValue={(selected) =>
                  this.getSelectedCounties(state.abbr, selected)}
                multiple
              >
                <MenuItem value='all' key='all'>
                  <Checkbox checked={state.counties.length === 0} />
                  <em>All</em>
                </MenuItem>
                {counties.map((c) => {
                  const selectedCounty = state.counties.find(
                    (cc) => cc.geo_id === c.geo_id,
                  );
                  return (
                    <MenuItem value={selectedCounty || c} key={c.geo_id}>
                      <Checkbox checked={selectedCounty != null} />
                      <ListItemText primary={c.county_name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {i > 0 && (
              <IconButton
                onClick={() => {
                  this.onRemoveState(state.abbr);
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            )}
          </div>
        );
      });
    }
    // no states?
    return null;
  }

  clearMap() {
    if (this.mapRef) {
      this.mapRef.setFeatureState({ source: 'counties' }, { selected: false });
    }
  }

  selectOnMap(geoId, selected) {
    if (this.mapRef) {
      this.mapRef.setFeatureState(
        { id: geoId, source: 'counties' },
        { selected },
      );
    } else {
      console.log('MAP IS NOT READY!');
    }
  }

  setCountySelected(stateAbbr, geoId, selected) {
    // select county on map
    this.selectOnMap(geoId, selected);

    this.setState(
      (state) => {
        let stateObj = state.states.find((s) => s.abbr === stateAbbr);
        if (!stateObj && selected) {
          stateObj = {
            abbr: stateAbbr,
            counties: [],
            countiesOpen: false,
          };
          state.states.push(stateObj);
        } else if (!stateObj && !selected) {
          // no changes needed
          return {};
        }

        const i = stateObj.counties.findIndex((c) => c.geo_id === geoId);
        if (i < 0 && selected) {
          let countyObj;
          const stateCounties = StoreUtil.get(
            this.props.allCounties,
            'counties_by_state',
            stateAbbr,
          );
          if (stateCounties && StoreUtil.isLoaded(stateCounties)) {
            countyObj = StoreUtil.getData(stateCounties).find(
              (c) => c.geo_id === geoId,
            );
          } else {
            this.fetchCounties(stateAbbr);
            countyObj = { geo_id: geoId, county_name: '', needsData: true };
          }
          if (countyObj) {
            stateObj.counties.push(countyObj);
          }
          if (
            stateCounties &&
            StoreUtil.isLoaded(stateCounties) &&
            StoreUtil.getData(stateCounties).length === stateObj.counties.length
          ) {
            // this is all counties, so change to "all"
            stateObj.counties = [];
          }
        } else if (i >= 0 && !selected) {
          stateObj.counties.splice(i, 1);
        } else if (stateObj.counties.length === 0 && !selected) {
          // first deselect of ALL
          const stateCounties = StoreUtil.get(
            this.props.allCounties,
            'counties_by_state',
            stateAbbr,
          );
          if (stateCounties && StoreUtil.isLoaded(stateCounties)) {
            stateObj.counties = StoreUtil.getData(stateCounties).filter(
              (c) => c.geo_id !== geoId,
            );
          } else {
            this.fetchCounties(stateAbbr);
            // then what?
          }
        }
        stateObj.countiesOpen = false;
        return {
          states: state.states,
          ...state,
          ...stateObj,
          dataModified: true,
        };
      },
      () => {
        this.zoomToBounds();
      },
    );
  }

  selectAllOnMap(stateAbbr, selected) {
    const stateCounties = StoreUtil.get(
      this.props.allCounties,
      'counties_by_state',
      stateAbbr,
    );
    let usedDb = false;
    if (stateCounties && StoreUtil.isLoaded(stateCounties)) {
      const counties = StoreUtil.getData(stateCounties);
      if (counties && counties.length > 0) {
        usedDb = true;
        counties.forEach((county) => {
          this.selectOnMap(county.geo_id, selected);
        });
      }
    }

    if (!usedDb && this.state.geodata) {
      const fips = StateUtil.getFipsForAbbr(stateAbbr);
      if (fips) {
        const features = this.state.geodata.features.filter(
          (f) => f.properties.STATEFP === fips,
        );

        features.forEach((feature) => {
          this.selectOnMap(feature.properties.GEOID, selected);
        });
      }
    }
  }

  togglePrivate = (_event, checked) => {
    if (!checked) {
      this.setState({ isPrivate: false, dataModified: true });
    } else {
      this.setState({ isPrivate: true, dataModified: true });
    }
  };

  zoomToBounds() {
    if (this.state.states && this.state.states.length > 0) {
      const bounds = new LngLatBounds();
      let count = 0;
      this.state.states.forEach((state) => {
        if (state.counties.length === 0) {
          if (!state.bounds) {
            this.setStateBounds(state);
          }
          if (state.bounds) {
            bounds.extend(state.bounds);
            count++;
          }
        } else {
          state.counties.forEach((county) => {
            if (!county.bounds) {
              this.setCountyBounds(county);
            }
            if (county.bounds) {
              bounds.extend(county.bounds);
              count++;
            }
          });
        }
      });
      if (count > 0) {
        this.mapRef.fitBounds(bounds, { padding: 40, duration: 500 });
      }
    }
  }

  setCountyBounds(county) {
    if (this.state.geodata) {
      const feature = this.state.geodata.features.find(
        (f) => f.properties.GEOID === county.geo_id,
      );
      if (feature) {
        const bounds = new LngLatBounds();
        let count = 0;
        feature.geometry.coordinates.forEach((a) => {
          a.forEach((f) => {
            count++;
            bounds.extend(f);
          });
        });
        if (count > 0) {
          county.bounds = bounds;
        }
      }
    }
  }

  setStateBounds(state) {
    if (this.state.geodata) {
      const fips = StateUtil.getFipsForAbbr(state.abbr);
      const features = this.state.geodata.features.filter(
        (f) => f.properties.STATEFP === fips,
      );
      if (features) {
        const bounds = new LngLatBounds();
        let count = 0;
        features.forEach((feature) => {
          feature.geometry.coordinates.forEach((a) => {
            a.forEach((f) => {
              count++;
              bounds.extend(f);
            });
          });
        });
        if (count > 0) {
          state.bounds = bounds;
        }
      }
    }
  }

  buildName() {
    if (this.state.byPostal) {
      const zips = this.postalsToArray(this.state.postals);
      if (zips.length < 4) {
        return zips.join(', ');
      }
      return `${zips.length} Zip Codes`;
    }
    const useAbbr = this.state.states.length > 2;
    return this.state.states
      .map((state) => {
        let name = state.abbr;
        if (!useAbbr) {
          name = StateUtil.abbr2Name(state.abbr);
        }

        if (state.counties.length === 0) {
          return name;
        }

        return `${name}(${state.counties.length})`;
      })
      .join(', ');
  }

  postalsToArray(postals) {
    if (postals && postals.length > 0) {
      return postals.split(/[,\s]+/).map((p) => {
        p = p.replace(/\D/g, '');
        if (p.length > 5) {
          p = p.substring(0, 5);
        }
        return p;
      });
    }
    return [];
  }

  onSetMapRef = (mapRef) => {
    this.mapRef = mapRef;
  };

  onMapLoaded = () => {
    this.setInitialMapState(false);
  };

  onNameChange = (event) => {
    const name = event.target.value;
    this.setState({
      name,
      dataModified: true,
      editedName: true,
    });
  };

  onPostalChange = (event) => {
    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      const postalText = event.target.value;
      let postalString = this.postalsToArray(postalText).join(', ');
      if (postalText.endsWith(',')) {
        postalString = postalString.slice(0, -2);
      }
      this.setState({
        postals: postalString,
        postalList: this.postalsToArray(postalString),
        dataModified: true,
      });
      return;
    }
    const postals = event.target.value;
    this.setState({
      postals: this.postalsToArray(postals).join(', '),
      postalList: this.postalsToArray(postals),
      dataModified: true,
    });
  };

  onAddState = () => {
    let nextState = null;
    StateUtil.getStates().every((s) => {
      if (!this.state.states.find((selected) => selected.abbr === s.abbr)) {
        nextState = s.abbr;
        return false;
      }
      return true;
    });

    this.selectAllOnMap(nextState, true);

    this.setState(
      (state) => {
        state.states.push({
          abbr: nextState,
          counties: [],
          countiesOpen: false,
        });

        return {
          states: state.states,
          dataModified: true,
        };
      },
      () => {
        this.fetchCounties(nextState);
        this.zoomToBounds();
      },
    );
  };

  onRemoveState = (abbr) => {
    this.setState(
      (state) => {
        const i = state.states.findIndex((s) => s.abbr === abbr);
        if (i >= 0) {
          this.selectAllOnMap(abbr, false);
          state.states.splice(i, 1);
        }
        return {
          states: state.states,
          dataModified: true,
        };
      },
      () => {
        this.zoomToBounds();
      },
    );
  };

  onStateChange = (oldStateAbbr, newStateAbbr) => {
    this.setState(
      (state) => {
        const newState = state.states.find((s) => s.abbr === oldStateAbbr);
        newState.abbr = newStateAbbr;
        newState.bounds = null;
        newState.counties = [];

        this.selectAllOnMap(oldStateAbbr, false);
        this.selectAllOnMap(newStateAbbr, true);

        if (
          !StoreUtil.isLoaded(
            StoreUtil.get(
              this.props.allCounties,
              'counties_by_state',
              state.abbr,
            ),
          )
        ) {
          this.fetchCounties(newStateAbbr);
        }

        let newName = state.name;
        // If the user hasn't entered a name yet, default it to the selected state
        if (!state.editedName) {
          newName = StateUtil.abbr2Name(newState.abbr);
        }

        return {
          states: state.states,
          dataModified: true,
          name: newName,
        };
      },
      () => {
        this.zoomToBounds();
      },
    );
  };

  onOpenCounties = (stateAbbr, isOpen) => {
    this.setState((state) => {
      const newState = state.states.find((s) => s.abbr === stateAbbr);
      newState.countiesOpen = isOpen;
      return {
        states: state.states,
      };
    });
  };

  onCountyChange = (stateAbbr, counties) => {
    // console.log(`on county change: [${stateAbbr}]`, counties);
    // if most recently selected is all
    if (counties.length === 0 || counties[counties.length - 1] === 'all') {
      counties = [];
      this.selectAllOnMap(stateAbbr, true);

      this.setState(
        (state) => {
          const newState = state.states.find((s) => s.abbr === stateAbbr);
          newState.counties = counties;
          newState.countiesOpen = false;

          return {
            states: state.states,
            dataModified: true,
          };
        },
        () => {
          this.zoomToBounds();
        },
      );
      return;
    }

    const stateObj = this.state.states.find((s) => s.abbr === stateAbbr);
    const oldCounties = stateObj.counties;

    // something has been added
    if (counties.length > oldCounties.length) {
      // if first one is All
      if (counties.length > 0 && counties[0] === 'all') {
        counties.shift(); // remove all
        this.selectAllOnMap(stateAbbr, false);
      }
      // only the newest one gets added
      this.setCountySelected(
        stateAbbr,
        counties[counties.length - 1].geo_id,
        true,
      );
      // something has been removed
    } else if (counties.length < oldCounties.length) {
      // console.log('something as removed');
      let countyRemoved = null;
      oldCounties.every((c1) => {
        if (!counties.find((c2) => c1.geo_id === c2.geo_id)) {
          countyRemoved = c1.geo_id;
          return false;
        }
        return true;
      });
      if (countyRemoved) {
        this.setCountySelected(stateAbbr, countyRemoved, false);
      }
    }
  };

  getSelectedCounties = (stateAbbr, selected) => {
    if (selected.length === 0 || selected[0] === 'all') {
      return <em>All Counties</em>;
    }

    if (selected.length > 3) {
      return `${selected.length} Counties`;
    }
    // Remove County at end of names to shorten
    const names = selected.map((c) => c.county_name.replace(' County', ''));
    return names.join(', ');
  };

  onCancel = (event, reason) => {
    const { dataModified } = this.state;

    if (dataModified && reason && reason === 'backdropClick') {
      this.setState({ confirmCloseOpen: true });
      return;
    }

    this.onConfirmCancel();
  };

  onConfirmCancel = () => {
    const { onClose } = this.props;

    this.onCloseConfirmCancel();

    if (onClose) {
      onClose();
    }
  };

  onCloseConfirmCancel = () => {
    this.setState({ confirmCloseOpen: false });
  };

  onSave = () => {
    let name = this.state.name;
    if (!this.state.name || this.state.name.length === 0) {
      name = this.buildName();
    }
    let states = null;
    let postals = null;
    if (this.state.byPostal) {
      postals = this.state.postals.split(',').map((p) => p.trim());
    } else {
      states = this.state.states.map((state) => ({
        abbr: state.abbr,
        counties: state.counties,
      }));
    }
    const isPrivate = this.state.isPrivate;
    if (this.props.onSave) {
      let id;
      if (this.props.market && this.props.market.id) {
        id = this.props.market.id;
      }
      this.props.onSave({
        id,
        name,
        states,
        postals,
        isPrivate,
      });
    }
  };

  onTabChange = (event, newValue) => {
    if (
      (this.state.postals && this.state.postals.length > 0) ||
      (this.state.states &&
        this.state.states.length !== 1 &&
        this.state.states[0].abbr !== 'PA' &&
        this.state.states[0].counties &&
        this.state.states[0].counties.length > 0)
    ) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Editing By Zip Code',
          content:
            'You currently must choose to add/edit either zip codes or counties to a market. Both at the same time is not yet supported.',
          confirmLabel: 'OK',
          onConfirm: () => {
            this.setState({
              byPostal: newValue === 'postal',
            });
          },
          onCancel: () => {
            // do nothing
          },
        }),
      );
    } else {
      this.setState({
        byPostal: newValue === 'postal',
      });
    }
  };
}

EditMarketDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  market: PropTypes.object,
  allCounties: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    allCounties: state.counties,
  };
}

const styled = withStyles(styles)(EditMarketDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as EditMarketDialog };
